import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { AccountBalance, AddBusiness, AddModeratorOutlined, Assessment, AutoAwesomeMosaicOutlined, BeenhereOutlined, AddHomeWork, Category, CategoryOutlined, ChatBubble, ContactPhone, Discount, Domain, Facebook, FireTruck, Info, Inventory2, JoinInner, LocalShipping, Person, ReportOutlined, Settings, ShoppingBasket, ShoppingBasketRounded, Wallet, CardGiftcard, Topic } from "@mui/icons-material";
import RedeemIcon from '@mui/icons-material/Redeem';
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const sidebarStyle = {
    paddingLeft: isCollapsed ? undefined : theme.direction === "rtl" ? "0" : "10%",
    paddingRight: isCollapsed ? undefined : theme.direction === "rtl" ? "10%" : "0",
  };

  const mergedStyles = {
    "& .pro-sidebar-inner": {
      background: `${colors.primary[400]} !important`,
      height:"100%"
    },
    "& .pro-icon-wrapper": {
      backgroundColor: "transparent !important",
    },
    "& .pro-inner-item": {
      padding: "5px 35px 5px 20px !important",
    },
    "& .pro-inner-item:hover": {
      color: "#868dfb !important",
    },
    "& .pro-menu-item.active": {
      color: "#6870fa !important",
    },
  };
  return (
    <Box sx={mergedStyles}>
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <img
                  alt="profile-user"
                  width="60px"
                  height="40px"
                  src={`../../logo.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
                <Typography variant="h4" color={colors.grey[100]}>
                  {t("Offers")}
                </Typography>
                
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>


          <Box paddingRight={isCollapsed ? undefined : "10%"} paddingLeft={isCollapsed ? undefined : "10%"} textAlign={"start"}>

            <Item
              title= {t("Dashboard")}
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title= {t("Admins")}
              to="/admins"
              icon={<AddModeratorOutlined/>}
              selected={selected}
              setSelected={setSelected}
            />

            {!isCollapsed&&<Typography
              variant="h5"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 0" ,fontWeight:"bold",textAlign:"start"}}
            >
              {t("Data")}
            </Typography>
            }

            <Item
              title= {t("Categories")}
              to="/categories"
              icon={<CategoryOutlined/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title= {t("Sections")}
              to="/sections"
              icon={<AutoAwesomeMosaicOutlined/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title= {t("Advertising")}
              to="/advertisings"
              icon={<BeenhereOutlined/>}
              selected={selected}
              setSelected={setSelected}
            />
             <Item
              title= {t("Coupons")}
              to="/copouns"
              icon={<Discount/>}
              selected={selected}
              setSelected={setSelected}
            />
             <Item
              title= {t("CustomSlider")}
              to="/customSlide"
              icon={<Topic/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title= {t("Gifts")}
              to="/gifts"
              icon={<CardGiftcard/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title= {t("OrderGift")}
              to="/orderGift"
              icon={<CardGiftcard/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title= {t("Points")}
              to="/point"
              icon={<CardGiftcard/>}
              selected={selected}
              setSelected={setSelected}
            />

            {!isCollapsed&&<Typography
              variant="h5"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 0" ,fontWeight:"bold",textAlign:"start"}}
            >
              {t("Management")}
            </Typography>
            }
       
            <Item
              title= {t("Providers")}
              to="/providers"
              icon={<AddBusiness/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title= {t("ProviderDepartment")}
              to="/providerDepartment"
              icon={<AddHomeWork/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title= {t("Vouchers")}
              to="/vouchers"
              icon={<Inventory2/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title= {t("Clients")}
              to="/clients"
              icon={<Person/>}
              selected={selected}
              setSelected={setSelected}
            />
    
            <Item
              title= {t("Orders")}
              to="/orders"
              icon={<ShoppingBasket/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title= {t("Reports")}
              to="/reports"
              icon={<Assessment/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title= {t("ReportsVouchers")}
              to="/reportsVouchers"
              icon={<Assessment/>}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title= {t("Accounts")}
              to="/accounts"
              icon={<AccountBalance/>}
              selected={selected}
              setSelected={setSelected}
            />

    
          {!isCollapsed &&
            <Typography
            variant="h5"
            color={colors.grey[300]}
            sx={{ m: "15px 0 5px 0" ,fontWeight:"bold",textAlign:"start"}}
            >
              {t("System")}
            </Typography>
            }

          

            <Item
              title= {t("Connections")}
              to="/connections"
              icon={<ContactPhone />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title= {t("SupportChats")}
              to="/chats"
              icon={<ChatBubble />}
              selected={selected}
              setSelected={setSelected}
            />
             <Item
              title= {t("Info")}
              to="/info"
              icon={<Info />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
