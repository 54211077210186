import { useMemo } from "react";
import AwesomeTable from "../../components/Awesome/AweomeTable";

import { t } from "i18next";
import TableHeaders from "./tableHeaders";
import Loader from "../../components/Common/Loader";
import TableActions from "./tableActions";
import {
  setAddMode,
  setEditMode,
  setItemToEdit,
  setDeleteMode,
  setItemToDelete,
  setItemToView,
  setViewMode,
} from "../../data/slices/advertisingSlice";
import { useDispatch, useSelector } from "react-redux";
import mapToFormData from "../../utils/mapToFormData";
import {
  useCreateAdvertisingMutation,
  useEditAdvertisingMutation,
  useGetAllAdvertisingsQuery,
  useDeleteAdvertisingMutation,
} from "../../data/api/advertising";
import FullScreenDialog from "../../components/Awesome/FullScreenDialog";
import AwesomeDialog from "../../components/Awesome/AwesomeDialog";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";
import { useGetCategorysSelectionListQuery } from '../../data/api/category';

function AdvertisingsPage() {
  const dispatch = useDispatch();

  const { data, isLoading, isFetching, isError, error, ...props } =
    useGetAllAdvertisingsQuery();

  const {
    itemToView,
    viewMode,
    addMode,
    deleteMode,
    editMode,
    itemToEdit,
    itemToDelete,
  } = useSelector((state) => state.advertising);

  const [editAdvertising, editAdvertisingResult] = useEditAdvertisingMutation();

  const [createAdvertising, createAdvertisingResult] =
    useCreateAdvertisingMutation();

  const categories = useGetCategorysSelectionListQuery();
  const [deleteAdvertising, deleteAdvertisingResult] =
    useDeleteAdvertisingMutation();

  const columns = useMemo(
    () =>
      TableHeaders.map((header) => ({
        ...header,
        header: t(header.header),
      })),
    []
  );

  if (deleteAdvertisingResult.isSuccess) {
    deleteAdvertisingResult.reset();
    props.refetch();
  }

  const setCreateOptions = () => {
    dispatch(setAddMode(true));
  };

  const onSubmit = (body) => {
    debugger;
    body = mapToFormData(body);
    if (addMode) createAdvertising({ body });
    if (editMode && itemToEdit) editAdvertising({ body, id: itemToEdit.id });
  };

  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }

  const advertisingTypeList = [
    { label: t("slider"), value: "slider" },
    { label: t("first_banner"), value: "first_banner" },
    { label: t("last_banner"), value: "last_banner" },
    { label: t("popup"), value: "popup" },
  ];

  return (
    <>
      {data && (
        <AwesomeTable
          columns={columns}
          data={data.data}
          showActions={true}
          RowActions={TableActions}
          setCreateOptions={setCreateOptions}
          showTopActions={true}
        />
      )}

      <FullScreenDialog
        data={itemToView}
        columns={[
          { accessorKey: "lable", header: t("Label") },
          // { accessorKey: "description", header: t("Description") },
          { accessorKey: "expiry_date", header: t("ExpiryDate") },
          { accessorKey: "type", header: t("Type") },
          { accessorKey: 'category.name', header: t('Category') },
          { accessorKey: "is_active", type: "bool", header: t("IsActive") },
          { accessorKey: "image_url", type: "image", header: t("Image") },
        ]}
        open={viewMode}
        onClose={() => {
          dispatch(setItemToView(null));
          dispatch(setViewMode(false));
        }}
      />

      <AwesomeDialog
        data={itemToEdit}
        columns={[
          { accessorKey: "lable", header: t("Label") },
          // { accessorKey: "description", header: t("Description") },
          { accessorKey: "expiry_date", type: "date", header: t("ExpiryDate") },
          { accessorKey: 'category_id',required:false,type:'select', header: t('Category'),list: categories.data?.data },
          {
            accessorKey: "type",
            type: "select",
            header: t("AdvertisingType"),
            list: advertisingTypeList,
          },
          {
            accessorKey: "image_url",
            type: "image",
            required: !itemToEdit,
            header: t("Image"),
          },
        ]}
        open={addMode || editMode}
        onClose={() => {
          dispatch(setEditMode(false));
          dispatch(setAddMode(false));
          dispatch(setItemToEdit(null));
        }}
        onSubmit={onSubmit}
      />

      <ConfirmationDialog
        open={deleteMode}
        onClose={() => {
          dispatch(setItemToDelete(null));
          dispatch(setDeleteMode(false));
        }}
        onConfirm={() => {
          deleteAdvertising({ id: itemToDelete.id });
        }}
        message={t("DeleteConfirmation")}
      />
    </>
  );
}

export default AdvertisingsPage;
