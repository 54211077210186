import { useMemo } from 'react';
import { t } from 'i18next';
import TableHeaders from './tableHeaders';
import Loader from '../../components/Common/Loader';
import { useGetVoucherReportMutation } from '../../data/api/home';

import ReportFilter from './reportFilter';
import AwesomeExportTable from '../../components/Awesome/AweomeExportTable';
import { Box, Typography } from '@mui/material';
import { tokens } from '../../../theme';
import { useTheme } from '@emotion/react';
import exportToPDF from '../../utils/exportToPDF';

function ReportsVouchersPage() {
  const [getVoucherReport, getVoucherReportResult] = useGetVoucherReportMutation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Columns for table: only Name and Connection Clicks
  const columns = useMemo(
    () => [
      { header: t('VoucherName'), accessorKey: 'name' },
      { header: t('Provider'), accessorKey: 'provider_name' },
      { header: t('Phone1'), accessorKey: 'phone1' },
      { header: t('ClicksPhone1Conection'), accessorKey: 'connection_clicks_phone1' },
      { header: t('Phone2'), accessorKey: 'phone2' },
      { header: t('ClicksPhone2Conection'), accessorKey: 'connection_clicks_phone2' },
    ],
    []
  );

  const onSubmit = (body) => {
    getVoucherReport(body);
  };

  const exportPDF = () => {
    exportToPDF("voucher-report");
  };

  return (
    <Box sx={{ height: "90%", overflow: 'scroll' }}>
      <ReportFilter onSubmit={onSubmit} />
      {getVoucherReportResult.isLoading ? (
        <Loader />
      ) : (
        getVoucherReportResult.data && (
          <Box>
            <AwesomeExportTable
              columns={columns}
              data={getVoucherReportResult.data.data} // Assuming data.data contains the list
              showActions={false}
              showTopActions
              exportPDF={exportPDF}
            />
            <div id="voucher-report">
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Typography
                  p={2}
                  variant="h3"
                  fontWeight="bold"
                  color={colors.greenAccent[500]}
                >
                  {"مجموع النقرات على الاتصالات الهاتف 1"}: {new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(
                    getVoucherReportResult.data.data.reduce(
                      (sum, record) => sum + (record.connection_clicks_phone1 || 0),
                      0
                    )
                  )}
                </Typography>
                <Typography
                  p={2}
                  variant="h3"
                  fontWeight="bold"
                  color={colors.greenAccent[500]}
                >
                  {"مجموع النقرات على الاتصالات الهاتف 2"}: {new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(
                    getVoucherReportResult.data.data.reduce(
                      (sum, record) => sum + (record.connection_clicks_phone2 || 0),
                      0
                    )
                  )}
                </Typography>
              </Box>
            </div>
          </Box>
        )
      )}
    </Box>
  );
}

export default ReportsVouchersPage;
