
import { useMemo } from 'react';
import AwesomeTable from '../../components/Awesome/AweomeTable';

import { t } from 'i18next';
import TableHeaders from './tableHeaders';
import { useAddCategoryMutation, useDeleteCategoryMutation, useEditCategoryMutation, useGetAllCategorysQuery } from '../../data/api/category';
import Loader from '../../components/Common/Loader';
import TableActions from './tableActions';
import { setAddMode, setDeleteMode, setEditMode, setItemToDelete, setItemToEdit, setItemToView, setViewMode } from '../../data/slices/providerSlice';
import { useDispatch, useSelector } from 'react-redux';
import FullScreenDialog from '../../components/Awesome/FullScreenDialog';
import AwesomeDialog from '../../components/Awesome/AwesomeDialog';
import mapToFormData from '../../utils/mapToFormData';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';

function CategorysPage() {


  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError, error } = useGetAllCategorysQuery();
  const {itemToView,viewMode,addMode,deleteMode,editMode,itemToEdit,itemToDelete} = useSelector(state=> state.provider);
  const [createCategory, createCategoryResult] = useAddCategoryMutation();
  const [editCategory, editCategoryResult] = useEditCategoryMutation();
  const [deleteCategory, deleteCategoryResult] = useDeleteCategoryMutation();
  
  const columns = useMemo(
    () =>  TableHeaders.map((header) => ({
      ...header,
      header: t(header.header), 
    })),
    [],
  );


  const setCreateOptions = ()=>{
    dispatch(setAddMode(true))
  }
   const onSubmit = (body) => {
    body = mapToFormData(body);
    console.log(body)
    if (addMode) createCategory(body);
    if (editMode && itemToEdit) editCategory({ body, id: itemToEdit.id });
  };


  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }


  return (
    <>
    {data && <AwesomeTable 
    columns={columns}
    data={data.data}
    showActions
    RowActions={TableActions}
    setCreateOptions={setCreateOptions}
    showTopActions 
    />}


    <FullScreenDialog
    
    data = {itemToView}
    columns={[  
      { accessorKey: 'name', header: t('Name') },
      // { accessorKey: 'image', type:'image', header: t('Image') },
      { accessorKey: 'icon',type:'image', header: t('Image') },
    ]}
    open={viewMode}
    onClose={() => {
      dispatch(setItemToView(null))
      dispatch(setViewMode(false))
    }}
    />

      <AwesomeDialog
      data = {itemToEdit}
      columns={[  
        { accessorKey: 'name',required:true, header: t('Name') },
        // { accessorKey: 'image',required: !itemToEdit , type:'image', header: t('Image') },
        { accessorKey: 'icon',required: !itemToEdit,type:'image', header: t('Image') },
      ]}
      open={addMode || editMode}
      onClose={() => {
    
      dispatch(setEditMode(false))
      dispatch(setAddMode(false))
      dispatch(setItemToEdit(null))

      }}
      onSubmit={onSubmit}
    />
      <ConfirmationDialog
      open={deleteMode}
      onClose={()=>{
      dispatch(setItemToDelete(null))
      dispatch(setDeleteMode(false))

      }}
      onConfirm={()=>{
        deleteCategory({id:itemToDelete.id})
      }}
      message={t("DeleteConfirmation")}
      />

    </>
  )
}

export default CategorysPage